import { PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import { flexCenter } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ServicesSection = styled.section`
    padding: 32px 16px;

    > h2 {
        margin: 0 auto 32px;
        text-transform: uppercase;
        text-align: center;
        width: fit-content;
    }

    @media (min-width: 1024px) {
        padding: 64px 32px;
    }
`;

const ServicesList = styled.div`
    ${flexCenter};
    flex-wrap: wrap;
    margin: calc(16px / -2);

    > * {
        margin: calc(16px / 2);
    }

    @media (min-width: 1280px) {
        flex-wrap: nowrap;
    }
`;

const Card = styled(CLink)`
    display: grid;
    align-items: end;
    justify-items: start;
    width: 100%;
    max-width: 438px;
    max-height: 304px;
    position: relative;
    overflow: hidden;

    > * {
        grid-area: 1/1;
    }

    ::before,
    ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        transition: transform 0.4s cubic-bezier(0.64, 0.57, 0.37, 1.33);
    }

    ::before {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }

    ::after {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        opacity: 0;
    }

    > .gatsby-image-wrapper {
        z-index: 1;
        transition: transform 0.4s cubic-bezier(0.64, 0.57, 0.37, 1.33);
    }

    > h2 {
        margin: 0 16px 90px;
        z-index: 4;
        color: ${({ theme }) => theme.colors.white};
        text-transform: capitalize;
        padding-bottom: 4px;

        ::after {
            width: 32px;
            height: 4px;
            transition: transform 0.4s cubic-bezier(0.64, 0.57, 0.37, 1.33);
            transform-origin: left;
        }
    }

    > button {
        z-index: 4;
        margin: 0 16px 16px;
        text-transform: capitalize;
    }

    :hover,
    :focus-visible {
        :before {
            opacity: 0;
        }

        ::after {
            opacity: 1;
        }

        > .gatsby-image-wrapper {
            transform: scale(1.25);
        }

        > h2 {
            ::after {
                transform: scaleX(10);
            }
        }
    }
`;

const theServices = ['cosmetic dentistry', 'dentures', 'emergency dentistry'];

export const Services = () => {
    const data = useStaticQuery(graphql`
        query homepageServices {
            cosmetic: file(relativePath: { eq: "homepage/services/cosmetic.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 438, height: 300)
                }
            }
            dentures: file(relativePath: { eq: "homepage/services/dentures.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 438, height: 300)
                }
            }
            emergency: file(relativePath: { eq: "homepage/services/emergency.jpg" }) {
                childImageSharp {
                    gatsbyImageData(width: 438, height: 300)
                }
            }
        }
    `);

    return (
        <ServicesSection>
            <h2>Our Services</h2>
            <ServicesList>
                {theServices.map((service, i) => (
                    <Card key={i} to={getNavLink(service)}>
                        <GatsbyImage
                            image={data[service.split(' ')[0]].childImageSharp.gatsbyImageData}
                            alt={service}
                        />
                        <h2>{service}</h2>
                        <PrimaryBtn to={getNavLink(service)}>Read about {service}</PrimaryBtn>
                        <div />
                    </Card>
                ))}
            </ServicesList>
        </ServicesSection>
    );
};
