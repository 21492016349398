import quotes from '@a/icons/quotes.svg';
import styled from '@emotion/styled';

const Review = styled.div`
    padding: 32px 16px;
    width: 330px;
    border-radius: 32px;
    flex-shrink: 0;
    border: 1px solid transparent;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(223.96deg, #00989d 5.9%, rgba(255, 255, 255, 0) 48.66%, #00989d 97.07%)
            border-box;
    margin-right: 16px;

    ::before {
        content: url(${quotes});
    }

    :hover,
    :focus {
        box-shadow: 0px 32px 128px -64px rgba(12, 88, 93, 0.3);
    }

    > p {
        :first-of-type {
            margin: 32px 0 16px;
        }

        :last-of-type {
            margin: 0;
            font-weight: 700;
        }
    }

    :last-of-type {
        margin-right: 0;
    }

    @media (min-width: 1024px) {
        margin-right: 32px;

        :last-of-type {
            margin-right: 32px;
        }
    }
`;

type ReviewProps = {
    text: string;
    name: string;
};

export const SingleReview = ({ text, name }: ReviewProps) => {
    return (
        <Review>
            <p>{text}</p>
            <p>{name}</p>
        </Review>
    );
};
