import { PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { px } from '@/layout/styles/classes';
import { SingleReview } from '@/SingleReview';
import styled from '@emotion/styled';
import { useDragScroll } from '@s/hooks/useDragScroll';
import { useRef } from 'react';

const ReviewSection = styled.section`
    padding-top: 32px;

    @media (min-width: 1024px) {
        padding: 0;
        display: flex;
    }
`;

const Slider = styled.div`
    ${px};
    padding-top: 32px;
    padding-bottom: 64px;
    display: flex;
    overflow-x: auto;

    @media (pointer: fine) {
        overflow-x: hidden;
    }

    @media (min-width: 1024px) {
        padding: 64px 0;
    }
`;

const Box = styled.div`
    margin-left: 16px;
    max-width: 444px;

    h2 {
        text-transform: uppercase;
        margin: 0;
    }

    p {
        margin: 16px 0 32px;
    }

    @media (min-width: 768px) {
        margin-left: 32px;
    }

    @media (min-width: 1024px) {
        margin-right: ${({ theme }) => theme.spacing.xl};
        margin-left: ${({ theme }) => theme.spacing.xxl};
        flex-shrink: 0;
        align-self: center;
    }
`;

const allReviews = [
    {
        text: "Finally! A dentist that doesn't make me scared to schedule my next appointment... Dr. Lovelace spent the time to make me feel comfortable and was incredibly knowledgeable... I even felt like he thought my time was valuable and that he made sure I wasn't just sitting in a chair waiting. Dr. Lovelace checks all the boxes for what I want out of my dentist and I know he'll do the same for you.",
        name: 'Geoffrey M.',
    },
    {
        text: 'Dr. Lovelace was recommended to me. I drove several hours to see him for a second opinion for a root canal. Dr. Lovelace took such great care and was very gentle. The crown was for my front tooth. I recommend Dr. Lovelace to everyone. He’s a wonderful dentist!',
        name: 'Ruthann D.',
    },
    {
        text: "Dr Lovelace is the best young up and coming dentist in this area! His skill in diagnosis and treatment are second to none... Dr. Lovelace's attention to detail in treatment gave me confidence to know that what he repaired was done right the first time with no issues at all. It's a pleasure to go to the dentist now that I've found a professional that cares! Thank you again Dr. Lovelace!",
        name: 'Rick',
    },
    {
        text: 'What an amazing experience with Dr. Lovelace! He is professional, knowledgeable, and genuine. Dr. Lovelace and his staff are very friendly and offered detailed explanations of every aspect of my care. Their expertise is matched by their kindness! I have seen Dr. Lovelace several times and my experience is always very pleasant and surprisingly different than other offices. Thank you Dr. Lovelace and staff- My family and I will continue using Lovelace Family Dentistry and recommend this practice without hesitation!',
        name: 'Barbara M.',
    },
];

export const Reviews = () => {
    const ref = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLInputElement>;
    const { events } = useDragScroll(ref);

    return (
        <ReviewSection>
            <Box>
                <h2>Reviews</h2>
                <p>Read what our satisfied patients have to say. </p>
                <PrimaryBtn
                    as={CLink}
                    to="https://www.google.com/search?q=lovelace+family+dentistry+fl&rlz=1C1CHBF_enPK1016PK1016&ei=xYQXY9SvELioqtsP3qGGwAY&ved=0ahUKEwiUpqzt2YD6AhU4lGoFHd6QAWgQ4dUDCA4&uact=5&oq=lovelace+family+dentistry+fl&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABMgUIIRCgATIFCCEQoAEyBQghEKABMgUIIRCgATIFCCEQqwI6BAgAEEc6BQghEJIDSgQIQRgASgQIRhgAULgKWJMyYNwzaABwAngAgAG3AYgBiwSSAQMwLjOYAQCgAQHIAQTAAQE&sclient=gws-wiz#lrd=0x88e433f80c84d52b:0x73c7c6a0e58e88d9,1"
                    dark="true"
                >
                    Write a Review
                </PrimaryBtn>
            </Box>
            <Slider ref={ref} {...events}>
                {allReviews.map((review, i) => (
                    <SingleReview {...review} key={i} />
                ))}
            </Slider>
        </ReviewSection>
    );
};
