import styled from '@emotion/styled';
import { ImageArticle } from './ImageArticle';

export const IA = styled(ImageArticle)`
    :first-of-type {
        margin-top: 0;
    }

    > div:last-of-type {
        max-width: 560px;

        > a {
            margin-top: 32px;
        }
    }

    @media (min-width: 768px) and (max-width: 1023px) {
        > div:last-of-type {
            max-width: 592px;
        }
    }

    @media (min-width: 1024px) {
        > div:last-of-type {
            > h2 {
                margin-bottom: 64px;
            }

            > a {
                margin-top: 64px;
            }
        }
    }
`;

export const ImageWrapper = styled.div<{ right?: boolean }>`
    padding: 8px;
    max-width: 544px !important;
    border-radius: ${({ right }) => (right ? ' 0px 136px !important' : '136px 0px !important')};
    background-image: linear-gradient(
        223.96deg,
        #00989d 5.9%,
        rgba(255, 255, 255, 0) 48.66%,
        #00989d 97.07%
    );

    > .gatsby-image-wrapper {
        min-height: 375px;
        border-radius: ${({ right }) => (right ? ' 0px 128px ' : '128px 0px ')};
    }

    @media (min-width: 1024px) {
        max-height: 736px !important;

        > .gatsby-image-wrapper {
            height: 100%;
        }
    }
`;
