import { flexCenter, px } from '@/layout/styles/classes';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';

const ScrollBox = styled.section`
    ${px};
    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 64px;
    max-width: max-content;
    overflow-x: auto;

    @media (min-width: 1024px) {
        padding-bottom: 96px;
    }
`;

const Logos = styled.div`
    ${flexCenter};
    width: max-content;
    border-radius: 128px;
    padding: 16px 64px;
    box-shadow: -16px -16px 64px #ffffff, 0px 32px 64px rgba(40, 33, 32, 0.15);
    backdrop-filter: blur(64px);
    mix-blend-mode: normal;
    background-color: rgba(250, 250, 250, 0.8);

    > .gatsby-image-wrapper {
        margin-right: 24px;
        flex-shrink: 0;

        :last-of-type {
            margin-right: 0;
        }
    }

    @media (min-width: 768px) {
        > .gatsby-image-wrapper {
            margin-right: 32px;

            :last-of-type {
                margin-right: 0;
            }
        }
    }

    @media (min-width: 1024px) {
        > .gatsby-image-wrapper {
            margin-right: 64px;

            :last-of-type {
                margin-right: 0;
            }
        }
    }
`;

export const Associations = () => {
    return (
        <ScrollBox>
            <Logos>
                <StaticImage
                    src="../../../assets/images/homepage/associations/cigna.png"
                    alt="cigna"
                    layout="fixed"
                    width={108}
                    height={64}
                />
                <StaticImage
                    src="../../../assets/images/homepage/associations/metlife.svg"
                    alt="met life"
                    layout="fixed"
                    width={164}
                    height={36}
                />
                <StaticImage
                    src="../../../assets/images/homepage/associations/humana.svg"
                    alt="humana"
                    layout="fixed"
                    width={118}
                    height={25}
                />
                <StaticImage
                    src="../../../assets/images/homepage/associations/aetna.png"
                    alt="aetna"
                    layout="fixed"
                    width={108}
                    height={64}
                />
                <StaticImage
                    src="../../../assets/images/homepage/associations/care-credit.png"
                    alt="care-credit"
                    layout="fixed"
                    width={250}
                    height={32}
                />
            </Logos>
        </ScrollBox>
    );
};
