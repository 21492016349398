import { About } from '@/homepage/About';
import { Associations } from '@/homepage/Associations';
import { Hero } from '@/homepage/Hero';
import { Reviews } from '@/homepage/Reviews';
import { Services } from '@/homepage/Services';
import { Fragment } from 'react';

const Home: Page = () => (
    <Fragment>
        <Hero />
        <Services />
        <Associations />
        <About />
        <Reviews />
    </Fragment>
);

export default Home;
