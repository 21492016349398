import { PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { IA, ImageWrapper } from '@/ImageArticleBorder';
import { getNavLink } from '@/layout/NavLinks';
import { h3 } from '@/layout/styles/Typography';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';

export const About = () => {
    return (
        <section>
            <IA right>
                <ImageWrapper right>
                    <StaticImage
                        src="../../../assets/images/homepage/about/about-us.jpg"
                        alt="drone shot over commercial area"
                        width={528}
                        height={720}
                    />
                </ImageWrapper>

                <div>
                    <h2>ABOUT US</h2>

                    <p>
                        Lovelace Family Dentistry is a local family-owned practice in Nocatee,
                        Florida. Dr. Joshua Lovelace wanted to bring his community a dental
                        experience that makes patients feel at home and heard when talking about
                        their oral health.
                        <br /> <br />
                        Our office prides itself in mirroring the vibe the Lovelace’s felt when
                        coming into this beautiful community: the feeling of care and never meeting
                        a stranger. Our team wants to make a positive impact in our area by being
                        involved with the community, not only as dental providers but as leaders in
                        treating others the way that they want to be treated.
                        <br /> <br />
                        The mission of Lovelace Family Dentistry is to give the utmost care by
                        listening to all of the patient’s needs and wants. Our office incorporates
                        proven treatments, new technology, and quality dentistry to reach those
                        goals as a team.
                        <br /> <br />
                        Our vision is to create a practice that you will grow with, like a family,
                        and be proud to call home.
                    </p>
                    <PrimaryBtn as={CLink} to={getNavLink('contact us')} dark="true">
                        Contact Us
                    </PrimaryBtn>
                </div>
            </IA>
            <IA>
                <ImageWrapper>
                    <StaticImage
                        src="../../../assets/images/doctor.jpg"
                        alt="doctor joshua lovelace"
                        width={528}
                        height={720}
                    />
                </ImageWrapper>

                <div>
                    <h2>
                        Dr joshua r lovelace
                        <span
                            css={theme => css`
                                ${h3};
                                display: block;
                                margin: 0;
                                color: ${theme.colors.accent};
                            `}
                        >
                            DMD, MS
                        </span>
                    </h2>

                    <p>
                        Dr. Joshua Lovelace grew up in Jupiter, Florida where going to the beach or
                        fishing on a boat was mandatory almost every weekend. So, it was an easy
                        transition to the Nocatee area and all its outdoor family fun. He enjoys
                        spending his weekends now at the Spray or Splash parks and countless other
                        activities with his wife, Alexis, and two daughters, Mackenzie and Reese.
                        The Nocatee community welcomed the Lovelace family in 2020 and inspired them
                        to open a family centered practice right in the heart of Nocatee. They
                        strive to give patients that same welcoming feeling they continue to receive
                        from residents in this great community.
                    </p>
                    <PrimaryBtn as={CLink} to={getNavLink('about us')} dark="true">
                        Read More About Dr Lovelace
                    </PrimaryBtn>
                </div>
            </IA>
        </section>
    );
};
