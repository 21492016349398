import { PrimaryBtn } from '@/buttons';
import CLink from '@/CLink';
import { getNavLink } from '@/layout/NavLinks';
import { flexCenter } from '@/layout/styles/classes';
import { hideUnderline } from '@/layout/styles/Typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import logo from '../../../assets/images/homepage/logo.svg';

const HomeHero = styled.section`
    display: grid;
    height: calc(100vh - 76px);
    color: ${({ theme }) => theme.colors.white};

    ::before {
        content: '';
        grid-area: 1/1;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.5));
        z-index: 2;
    }

    @media (min-width: 1024px) {
        position: relative;
        top: -96px;
        margin-bottom: -96px;
        height: 100vh;
    }
`;

const Box = styled.div`
    grid-area: 1/1;
    align-self: end;
    justify-self: center;
    padding: 32px 16px;
    z-index: 3;
    text-align: center;
    width: 100%;

    > p {
        margin: 0;
        letter-spacing: 0.5em;
    }

    > p,
    > h1 {
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.white};
    }

    > h1 {
        ${flexCenter};
        ${hideUnderline};
        flex-direction: column;
        margin: 8px 0;
        letter-spacing: 0.25em;

        > img {
            margin-bottom: 8px;
        }
    }

    @media (min-width: 768px) {
        padding: 32px;
        margin: ${({ theme }) => theme.spacing.md};

        > p,
        > h1 {
            font-size: 1.5rem;
        }
    }
`;

const bgStyle = css`
    grid-area: 1/1;
    z-index: 1;

    @media (min-width: 500px) {
        img {
            object-position: top;
        }
    }
`;

export const Hero = () => {
    return (
        <HomeHero>
            <Box>
                <p>welcome to</p>
                <h1>
                    <img src={logo} alt="lovelace" />
                    Family Dentistry
                </h1>

                <PrimaryBtn as={CLink} to={getNavLink('about us')}>
                    MEET DR LOVELACE
                </PrimaryBtn>
            </Box>
            <StaticImage
                src="../../../assets/images/homepage/bg.jpg"
                alt="smiling family"
                css={bgStyle}
                loading="eager"
            />
        </HomeHero>
    );
};
