import { flexCenter, flexStart } from '@/layout/styles/classes';
import styled from '@emotion/styled';

export const ImageArticle = styled.article<{ right?: boolean }>`
    margin-top: 32px;
    margin-bottom: 32px;

    > div {
        :first-of-type {
            max-width: 688px;
            min-height: 375px;
            border-radius: ${({ right }) => (right ? '128px 0' : ' 128px 0px')};
            opacity: 0.9999;
            overflow: hidden;
            margin: 0 16px;
            box-shadow: -64px 64px 128px -64px rgb(12 88 93 / 20%);
        }

        :last-of-type {
            ${flexStart};
            justify-content: center;
            flex-direction: column;
            padding: 0 16px;
            width: 100%;
            max-width: 720px;
            margin-top: 32px;

            > h2 {
                margin: 0 0 32px;
                text-transform: uppercase;
            }

            > p {
                margin: 0 0 32px;

                :last-of-type {
                    margin-bottom: 0;
                }
            }

            > a {
                margin-top: 32px;
            }
        }
    }

    @media (min-width: 768px) {
        > div {
            :first-of-type {
                margin: 0 32px;
            }
            :last-of-type {
                max-width: 752px;
                padding: 0 32px;
            }
        }
    }

    @media (min-width: 1024px) {
        ${flexCenter};
        margin-top: 64px;
        margin-bottom: 64px;

        > div {
            :first-of-type {
                margin-right: ${({ theme, right }) => (right ? '32px' : theme.spacing.md)};
                margin-left: ${({ theme, right }) => (right ? theme.spacing.md : '32px')};
                order: ${({ right }) => (right ? 1 : 'unset')};
                max-height: 688px;
                width: 50%;
            }

            :last-of-type {
                width: 50%;
                max-width: 720px;
                margin-top: 0;
                padding-right: ${({ right }) => (right ? 0 : '32px')};
                padding-left: ${({ right }) => (right ? '32px' : 0)};
            }
        }
    }
`;
